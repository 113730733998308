"use client";

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import MUILink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NextLink from "next/link";
import SpecialEnquiryFormWrapper from "./SpecialEnquiryFormWrapper";

export default function SideContactIcons() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box position="fixed" zIndex={9} right="0" top="40%">
        <Box display="flex" flexDirection="column" textAlign="center">
          <MUILink
            component={NextLink}
            href="tel:7042434524"
            passHref
            sx={{
              bgcolor: "#3944bc",
              p: 1,
              ":hover": { transition: ".3s ease-in-out", scale: "1.2" },
            }}
            aria-label="Call us"
          >
            <AddIcCallIcon sx={{ color: "#fff" }} />
          </MUILink>

          <Typography
            component="button"
            variant="subtitle1"
            sx={{
              border: "0",
              writingMode: "vertical-lr",
              color: "#fff",
              p: 1,
              bgcolor: "#991515",
              cursor: "pointer",
              textDecoration: "none",
              ":hover": {
                scale: "1.2",
                transition: ".3s ease-in-out",
                zIndex: 99,
              },
            }}
            onClick={handleOpen}
            aria-label="Enquiry"
          >
            Enquiry
          </Typography>

          <MUILink
            component={NextLink}
            href="https://wa.me/7042994524"
            target="_blank"
            passHref
            sx={{
              bgcolor: "#25d366",
              p: 1,
              ":hover": { transition: ".3s ease-in-out", scale: "1.2" },
            }}
            aria-label="Contact via WhatsApp"
          >
            <WhatsAppIcon sx={{ color: "#fff" }} />
          </MUILink>
        </Box>
      </Box>

      <Dialog
        fullWidth
        scroll="body"
        maxWidth="sm"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 0, m: 0 }}>
          <SpecialEnquiryFormWrapper
            setOpen={setOpen}
            action="ThankYouMessage"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
