"use client";

import React from "react";
import { Dialog, DialogContent } from "@mui/material";

export default function Popup({ popup }) {
  const [openPopup, setOpenPopup] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (popup && popup._id && popup.is_disabled) {
        setOpenPopup(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleClose = () => {
    setOpenPopup(false);
  };

  if (openPopup) {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: 2 }}>
          <iframe
            style={{ border: "none" }}
            loading="lazy"
            width="100%"
            allowFullScreen
            height="300"
            src={`${popup?.video_path}`}
            title={`${popup?.title}`}
          />
        </DialogContent>
      </Dialog>
    );
  }
  return null;
}
